import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src199531276/src/mojility.ca/src/components/layout.js";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="404 Not Found" mdxType="SEO" />
    <h1>{`Page Not Found`}</h1>
    <p>{`Sorry, the requested page does not exist here.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      